var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_join_invoices") } },
    [
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-form-model",
                {
                  ref: "searchForm",
                  attrs: {
                    model: _vm.form,
                    "label-col": { xs: { span: 24 }, lg: { span: 5 } },
                    "wrapper-col": { xs: { span: 24 }, lg: { span: 9 } },
                    layout: "vertical"
                  }
                },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { prop: "branch", label: _vm.$t("lbl_branch") } },
                    [
                      _c("CSelectBranch", {
                        model: {
                          value: _vm.form.branch,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "branch", $$v)
                          },
                          expression: "form.branch"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        prop: "joinInvoiceNo",
                        label: _vm.$t("lbl_join_invoice_no")
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: {
                            "show-search": true,
                            "filter-option": false,
                            "allow-clear": true,
                            loading: _vm.joinInvoiceNoOption.fetching
                          },
                          on: { search: _vm.getJoinInvoiceNo },
                          model: {
                            value: _vm.form.joinInvoiceNo,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "joinInvoiceNo", $$v)
                            },
                            expression: "form.joinInvoiceNo"
                          }
                        },
                        _vm._l(_vm.joinInvoiceNoOption.data, function(
                          item,
                          index
                        ) {
                          return _c(
                            "a-select-option",
                            {
                              key: index,
                              attrs: { value: item.documentNumber }
                            },
                            [_vm._v(" " + _vm._s(item.documentNumber) + " ")]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        prop: "customerName",
                        label: _vm.$t("lbl_customer_name")
                      }
                    },
                    [
                      _c("CSelectCustomer", {
                        model: {
                          value: _vm.form.customerName,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "customerName", $$v)
                          },
                          expression: "form.customerName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        prop: "joinInvoiceDate",
                        label: _vm.$t("lbl_join_invoice_date")
                      }
                    },
                    [
                      _c("a-range-picker", {
                        attrs: { format: _vm.DEFAULT_DATE_FORMAT },
                        model: {
                          value: _vm.form.joinInvoiceDate,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "joinInvoiceDate", $$v)
                          },
                          expression: "form.joinInvoiceDate"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: { prop: "currency", label: _vm.$t("lbl_currency") }
                    },
                    [
                      _c("CSelectCurrency", {
                        model: {
                          value: _vm.form.currency,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "currency", $$v)
                          },
                          expression: "form.currency"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { span: 12 } },
            [
              _c(
                "a-space",
                [
                  _c("a-button", { on: { click: _vm.resetForm } }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_clear")) + " ")
                  ]),
                  _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "search",
                        loading: _vm.loadingSubmit
                      },
                      on: { click: _vm.handleSubmit }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 12, align: "end" } },
            [
              _c(
                "a-button",
                {
                  attrs: { type: "primary", icon: "plus" },
                  on: { click: _vm.createNew }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_create_new")) + " ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c("TableCustom", {
                attrs: {
                  "data-source": _vm.dataSource,
                  columns: _vm.tableColumns,
                  scroll: { x: "calc(700px + 50%)", y: 400 },
                  paginationcustom: true,
                  "default-pagination": false,
                  loading: _vm.loadingSubmit
                },
                on: { "on-view": _vm.viewRow }
              })
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 12 } },
            [
              _c("Pagination", {
                attrs: {
                  total: _vm.totalData,
                  "page-size-set": _vm.limit,
                  "id-pagination": "pagination1"
                },
                on: {
                  "response-pagesize-change": _vm.responsePageSizeChange,
                  "response-currentpage-change": _vm.responseCurrentPageChange
                }
              })
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 12, align: "end" } },
            [
              _c("a-tag", { attrs: { color: "#8c8c8c" } }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("lbl_total_found")) +
                    " : " +
                    _vm._s(_vm.totalData) +
                    " "
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }